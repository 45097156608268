import { ThemeProvider } from "@material-ui/core/styles";
import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/node";
import { useMemo, useState } from "react";
import Snackbar from "../components/assets/Snackbar";
import { AuthProvider, ProtectRoute } from "../src/AuthKeycloakProvider";
import { ErrorHandling } from "../src/ErrorContext";
import theme from "../src/theme";
import UserContext from "../src/UserContext";
import "../styles/globals.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations = [];
  if (
    process.env.NEXT_IS_SERVER === "true" &&
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
  ) {
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            "app:///"
          );
          frame.filename = frame.filename.replace(".next", "_next");
          return frame;
        },
      })
    );
  }

  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  });
}

function getUserType(keycloakState) {
  if (
    keycloakState?.authenticated === true &&
    (JSON.parse(sessionStorage.getItem("userSession"))?.isAdmin ||
      JSON.parse(sessionStorage.getItem("userSession"))?.isChamberUser)
  ) {
    return "authenticated";
  } else {
    return "notauthenticated";
  }
}

function MyApp({ Component, pageProps, err }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [keycloakstate, setKeycloakstate] = useState({
    keycloak: null,
    authenticated: false,
    token: null,
    userName: null,
    user_id: null,
    roles: null,
  });

  const value = useMemo(
    () => ({ keycloakstate, setKeycloakstate }),
    [keycloakstate, setKeycloakstate]
  );

  return (
    <UserContext.Provider value={value}>
      <AuthProvider>
        <ProtectRoute>
          <ThemeProvider theme={theme}>
            <ErrorHandling.Provider
              value={{ open, setOpen, handleClose, handleClick }}
            >
              <Snackbar />
              <Component {...pageProps} err={err} />
            </ErrorHandling.Provider>
          </ThemeProvider>
        </ProtectRoute>
      </AuthProvider>
    </UserContext.Provider>
  );
}

export default MyApp;
