import Snackbar from '@material-ui/core/Snackbar';
import { ErrorHandling } from '../../src/ErrorContext';
import { useContext } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import router from 'next/router';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    snackbar: {
        margin: theme.spacing(2),
    },
    buttonReload: {
        color: "#FFFFFF",
        marginLeft: theme.spacing(2)
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar = () => {

    const { open, handleClose } = useContext(ErrorHandling);
    const classes = useStyles();

    return (
        <div style={{ alignItems: 'center' }}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
            >
                <div style={{ alignItems: 'center' }}>
                    <Alert severity="error" action={
                        <Button color="inherit" size="small" className={classes.buttonReload} size="small" onClick={() => router.reload()}>
                            Ανανεωση
    </Button>
                    }>
                                Κάτι πήγε λάθος. Παρακαλώ προσπαθήστε ξανά!
                    </Alert>
                </div>
            </Snackbar >
        </div>
    );
};

export default SnackBar;