import { createMuiTheme } from '@material-ui/core/styles';
 
const theme = createMuiTheme({
  palette: {
    // primary: {
    //   main: "#664C90",
    //   light: "#917FB1",
    //   dark: "#543C87",
    // },
    // secondary: {
    //   main: "#A9D522",
    //   light: "#C0E26A",
    //   dark: "#90AE0D",
    // },
    // error: {
    //   main: "#FF0033",
    //   light: "#FF4C70",
    //   dark: "#B20023",
    // },
    // warning: {
    //   main: "#EED202",
    //   light: "#F3DF4D",
    //   dark: "#A69301",
    // },
    // success: {
    //   main: "#4BB543",
    //   light: "#82CC7D",
    //   dark: "#34942F",
    // },
    gray: {
      main: "#293845",
      light: "#69737C",
      dark: "#1C2730"
    }
  },
  // typography: {
  //   fontFamily: "Arial"
  // }
});

export default theme;
